<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col
          md="6"
          sm="8"
        >
          <b-card
            no-body
            class="m-4"
          >
            <b-card-body class="p-4">
              <b-form
                novalidate
                @submit.prevent="onSubmit"
              >
                <h1>Создание нового пароля</h1>
                <p class="text-muted">
                  Введите новый пароль
                </p>
                <b-form-group
                  id="passInputGroup2"
                  label="Пароль"
                  label-for="raw_password"
                >
                  <b-form-input
                    id="raw_password"
                    v-model.trim="$v.form.rawPassword.$model"
                    type="password"
                    name="raw_password"
                    :state="chkState('rawPassword')"
                    aria-describedby="input1LiveFeedback2"
                    placeholder="Пароль"
                  />
                  <b-form-invalid-feedback
                    id="input1LiveFeedback2"
                  >
                    Это поле обязательно для заполнения, пароль должен содержать: цифры, прописные и строчные буквы, минимум 8 символов
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="passInputGroup3"
                  label="Подтверждение пароля"
                  label-for="repeat_password"
                >
                  <b-form-input
                    id="repeat_password"
                    v-model.trim="$v.form.repeatPassword.$model"
                    type="password"
                    name="repeat_password"
                    :state="chkState('repeatPassword')"
                    aria-describedby="input1LiveFeedback3"
                    placeholder="Подтверждение пароля"
                  />
                  <b-form-invalid-feedback id="input1LiveFeedback3">
                    Пароли должны совпадать
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="loading"
                  :class="{'loading' : loading}"
                >
                  Подтвердить
                </b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import {validationMixin} from 'vuelidate';
import {
  required,
  minLength,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';
import notifications from '../components/mixins/notifications';
import {dropPassword} from '../services/api';

const strongPass = helpers.regex(
  'strongPass',
  /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
);

const formShape = {
  rawPassword: '',
  repeatPassword: '',
  drop: '',
};

export default {
  name: 'DropPassword',
  mixins: [validationMixin, notifications],
  data() {
    return {
      form: Object.assign({}, formShape),
      drop: this.$route.query.drop,
      loading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
  },
  validations: {
    form: {
      rawPassword: {
        required,
        minLength: minLength(8),
        strongPass,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('rawPassword'),
      },
      drop: {
        required,
      },
    },
  },
  methods: {
    phoneValidate(e) {
      if (/_/.test(e)) {
        this.$v.form.phone.$model = '';
      }
    },
    async onSubmit() {
      this.form.drop = this.drop;
      if (this.validate()) {
        this.loading = true;
        const response = await dropPassword(this.form);
        if (response && response.status === 200) {
          this.showSuccess('Пароль обновлен');
          this.$router.push({path: '/login'});
        }
        this.loading = false;
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      if (!field.$dirty) {
        return null;
      } else if (field.$invalid) {
        return false;
      } else {
        return true;
      }
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
